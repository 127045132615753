import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { userContext } from "../../context/UserContext";
import { url } from "../../helpers";
import { toast } from "react-toastify";

function VendorZakiriPackages() {
  const [Packages, setPackages] = useState([]);
  const { user, vendorData, setLoad } = useContext(userContext);

  async function fetchPackages() {
    setLoad(true);
    const response = await fetch(
      url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      setPackages(data?.data?.packages);
    } else {
      setLoad(false);
      toast.error("Internal Server Error");
    }
  }

  useEffect(() => {
    if (vendorData.length > 0) {
      fetchPackages();
    }
  }, [vendorData]);

  async function ZakiriPackageDelete(index_count) {
    setLoad(true);
    const UpdatePackage = Packages.filter(
      (item, index) => index !== index_count
    );
    setPackages(UpdatePackage);

    const response = await fetch(
      url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        method: "PUT",
        body: JSON.stringify({ data: { packages: UpdatePackage } }),
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      toast.success("Package Deleted Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 800);
    } else {
      setLoad(false);
      toast.error("Internal Server Error");
    }
  }
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    Zakiri Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="row align-items-center mb-4">
                  <div className="col-7">
                    <div className="dark-card-header">
                      <h2 className="card-title m-0">Packages List</h2>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="package-add-btn-div text-end">
                      <Link
                        className="btn btn-primary py-2 px-3"
                        to={"/vendor-zakiri-add-package"}
                      >
                        Add Packages
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {Packages.length > 0 ? (
                    Packages?.map((item, i) => (
                      <div key={i} className="col-md-12 col-lg-6 col-xl-4">
                        <div className="dark-card res-package-card">
                          <div className="res-pac-img package-pac-img mb-3">
                            <h3 className="font-bold">{item?.package_name}</h3>
                          </div>
                          <div className="res-pac-detail">
                            <h4 className="font-bold">
                              {item?.package_unit_price}KD
                            </h4>
                            <h6>Occasions : Birthday</h6>
                            <p className="text-light">
                              {item?.package_description}
                            </p>
                          </div>
                          <div className="res-pac-detail">
                            <div className="row">
                              <div className="col-6">
                                <button
                                  className="btn btn-danger py-2 w-100"
                                  onClick={() => ZakiriPackageDelete(i)}
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="col-6">
                                <Link
                                  to={`/vendor-zakiri-add-package?index=${i}`}
                                  className="btn btn-primary py-2 w-100"
                                >
                                  Edit
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="col-md-12 d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <h3 className="packeges-text">No Packages Found</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorZakiriPackages;
