import React, { Fragment } from 'react'

function Loader() {
    return (
        <div className="preloder">
            <div className="loder">

            </div>
            <div className='loader-logo'>
                <img src='/assets/images/logo.png' alt='img' className='img-fluid'/>
            </div>
        </div>
    )
}

export default Loader