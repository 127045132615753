import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { userContext } from "../../context/UserContext";
import { node_image_url, url } from "../../helpers";
import { toast } from "react-toastify";
import ZakiriDetail from "../ZakiriDetail";
import { json } from "react-router-dom";

function VendorZakiriProfileDetail() {
  const { user, setLoad } = useContext(userContext);
  const [vendorData, setVendorData] = useState([]);
  const [AllYouTubeVideoLink, setAllYouTubeVideoLink] = useState([]);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [Description, setDescription] = useState("");
  const [Type, setType] = useState("");
  const [Image, setImage] = useState("");
  const [coverPictureSrc, setCoverPictureSrc] = useState("");
  const [audioUpload, setAudioUpload] = useState("");
  const [youtubeVideoLink, setYoutubeVideoLink] = useState("");

  async function ZakiriProfileDetailUpdate(e) {
    e.preventDefault();
    setLoad(true);

    const send_data = {
      data: {
        qaari_name: Name,
        email: Email,
        phone: Mobile,
        address: Address,
        type: Type,
        description: Description,
        doctype: "Qaari Zakreen",
      },
    };

    let api_url = "";
    if (Image) {
      const formData = new FormData();
      formData.append("image", Image, Image.name);
      const cover_response = await fetch(node_image_url + "/upload-files-bg", {
        method: "POST",
        body: formData,
      });

      if (cover_response.ok == true) {
        const cover_data = await cover_response.json();
        setLoad(false);
        if (cover_data?.status == 200) {
          send_data.data.cover_picture = node_image_url + cover_data?.file_url;
        }
      } 
    }

      if (vendorData.length > 0) {
        api_url = `resource/Qaari Zakreen/${vendorData[0]?.name}`;
      } else {
        api_url = "resource/Qaari Zakreen";
      }

      const response = await fetch(url + api_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        method: vendorData.length > 0 ? "PUT" : "POST",
        body: JSON.stringify(send_data),
      });

      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();
        toast.success("Data Updated Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setLoad(false);
        toast.error("Internal Server Error");
      }
  }


  useEffect(() => {
    setName(user?.full_name);
    setEmail(user?.message?.email);
    // setPhone(data?.data[0]?.res_phone);
  }, [user])


  useEffect(() => {
    async function FetchDetails() {
      setLoad(true);
      const response = await fetch(
        url +
          "resource/Qaari Zakreen?limit_page_length=20&limit_start=0&fields=[%22*%22]",
        {
          headers: {
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
        }
      );
      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();
        setVendorData(data?.data);
        setName(data?.data[0]?.qaari_name);
        setEmail(data?.data[0]?.email);
        setMobile(data?.data[0]?.phone);
        setAddress(data?.data[0]?.address);
        setType(data?.data[0]?.type);
        setDescription(data?.data[0]?.description);
      } else {
        setLoad(false);
        toast.error("Internal Server Error");
      }
    }
    FetchDetails();
  }, []);

  async function FetchLinks() {
    const response = await fetch(
      url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    if (response.ok == true) {
      const data = await response.json();
      if (data?.data?.links?.length > 0) {
        setAllYouTubeVideoLink(
          data?.data?.links.map((item) => {
            return {
              zakreen_link: item?.zakreen_link,
            };
          })
        );
      }
    } else {
      toast.error("Internal Server Error");
    }
  }

  useEffect(() => {
    if (vendorData.length > 0) {
      FetchLinks();
    }
  }, [vendorData]);

  async function uploadAudio() {
    setLoad(true);

    if (audioUpload) {
      let send_data = {};
      const formData = new FormData();
      formData.append("audio", audioUpload, audioUpload.name);
      const cover_response = await fetch(node_image_url + "/upload-files-bg", {
        method: "POST",
        body: formData,
      });

      if (cover_response.ok == true) {
        setLoad(false);
        const cover_data = await cover_response.json();
        if (cover_data?.status == 200) {
          send_data["audio"] = node_image_url + cover_data?.file_url;
        }
      }

      if (send_data?.audio) {
        const response = await fetch(
          url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
            },
            method: "PUT",
            body: JSON.stringify({ data: send_data }),
          }
        );

        if (response.ok == true) {
          setLoad(false);
          const data = await response.json();
          toast.success("Data Updated Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setLoad(false);
          toast.error("Internal Server Error");
        }
      }
    } else {
      setLoad(false);
      toast.error("Please select audio file");
    }
  }

  async function deleteAudio() {
    setLoad(true);
    const response = await fetch(
      url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        method: "PUT",
        body: JSON.stringify({ data: { audio: "" } }),
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      toast.success("Data Updated Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } else {
      setLoad(false);
      toast.error("Internal Server Error");
    }
  }

  async function YoutubeVideoDelete(index_count) {
    setLoad(true);
    const updatedLinks = AllYouTubeVideoLink.filter(
      (item, index) => index != index_count
    );
    setAllYouTubeVideoLink(updatedLinks);

    const response = await fetch(
      url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
      {
        method: "PUT",
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        body: JSON.stringify({
          links: updatedLinks,
        }),
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      toast.success("Youtube Video Link Deleted Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 900);
    } else {
      toast.error("Internal Server Error");
    }
  }

  async function addYoutubeVideo() {
    setLoad(true);
    if (youtubeVideoLink) {
      let previous_links = AllYouTubeVideoLink;
      previous_links.push({
        zakreen_link: youtubeVideoLink,
      });
      const response = await fetch(
        url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
          method: "PUT",
          body: JSON.stringify({ data: { links: previous_links } }),
        }
      );

      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();
        toast.success("Data Updated Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 700);
      } else {
        setLoad(false);
        toast.error("Internal Server Error");
      }
    }
  }

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    Zakiri Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="restaurant-cover-img position-relative">
                  {coverPictureSrc ? (
                    <img
                      src={coverPictureSrc}
                      alt="img"
                      className="img-fluid"
                    />
                  ) : vendorData?.length > 0 && vendorData[0]?.cover_picture ? (
                    <img
                      src={`${vendorData[0]?.cover_picture}`}
                      alt="img"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src="/assets/images/zakiri-cover.jpg"
                      alt="img"
                      className="img-fluid"
                    />
                  )}
                  <div className="restaurant-cover-btn-div">
                    <input
                      className="d-none"
                      type="file"
                      id="rcb-id"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setCoverPictureSrc(reader.result);
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                    <label className="btn bg-white" for="rcb-id">
                      Add Cover Image
                    </label>
                  </div>
                </div>

                <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5 mb-4">
                  <div className="dark-card-header">
                    <h2 className="card-title mb-4">Zakiri information</h2>
                  </div>
                  <form
                    onSubmit={(e) => {
                      ZakiriProfileDetailUpdate(e);
                    }}
                  >
                    <div className="dark-card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Zakiri Name *</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Zakiri Name"
                              value={Name}
                              required
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Your Email *</label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Enter Email"
                              value={Email}
                              readOnly
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Type *</label>
                            <select
                              name="type"
                              id="type"
                              required
                              className="form-control"
                              onChange={(e) => setType(e.target.value)}
                              value={Type}
                            >
                              <option value="">Select Option..</option>
                              <option value="Qaari">Qaari</option>
                              <option value="Zakirin">Zakirin</option>
                              <option value="Both">Both</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Mobile Number *
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Enter Mobile Number"
                              value={Mobile}
                              required
                              minLength={8}
                              maxLength={8}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">Address *</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Address"
                              value={Address}
                              required
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Zakiri Description *
                            </label>
                            <textarea
                              className="form-control"
                              rows="4"
                              placeholder="Enter Zakiri Description"
                              value={Description}
                              required
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-9 order-2 order-sm-2 order-md-1 mt-3 mt-sm-3 mt-md-0">
                          <p className="m-0 text-center text-sm-center text-md-start">
                            To get started, please add your Zakiri packages to
                            our platform.{" "}
                            <a href="#" className="text-primary">
                              <u>Add Packages</u>
                            </a>
                          </p>
                        </div>
                        <div className="col-md-3 order-1 order-sm-1 order-md-2">
                          <div className="form-group text-center text-sm-center text-md-end">
                            <button
                              className="btn btn-primary py-3 px-5"
                              type="submit"
                            >
                              {vendorData.length > 0 ? "Update" : "Create"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {vendorData?.length > 0 && (
                  <>
                    <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5 mb-4">
                      <div className="dark-card-header">
                        <h2 className="card-title mb-4">Zakiri Audio</h2>
                      </div>
                      <div className="dark-card-body">
                        <div className="row mb-4">
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <input
                                className="form-control"
                                type="file"
                                onChange={(e) =>
                                  setAudioUpload(e.target.files[0])
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {vendorData[0]?.audio && (
                          <div className="attachment-list">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="audio-box">
                                  <audio
                                    controls
                                    src={vendorData[0]?.audio}
                                  ></audio>
                                  <button
                                    onClick={deleteAudio}
                                    className="btn btn-danger"
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row align-items-center mt-5">
                          <div className="col-md-12 order-1 order-sm-1 order-md-2">
                            <div className="form-group text-center text-sm-center text-md-end">
                              <button
                                onClick={uploadAudio}
                                className="btn btn-primary py-3 px-5"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5 mb-4">
                      <div className="dark-card-header">
                        <h2 className="card-title mb-4">
                          Zakiri Youtube Videos
                        </h2>
                      </div>
                      <div className="dark-card-body">
                        <div className="row mb-4">
                          <div className="col-md-10">
                            <div className="form-group mb-0">
                              <input
                                className="form-control"
                                placeholder="Youtube Link"
                                value={youtubeVideoLink}
                                onChange={(e) =>
                                  setYoutubeVideoLink(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group h-100 mb-0">
                              <button
                                onClick={addYoutubeVideo}
                                className="btn btn-outline-light h-100 w-100"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="attachment-list">
                          {AllYouTubeVideoLink.length > 0 && (
                            <table className="table table-bordered">
                              {AllYouTubeVideoLink.map((item, index) => (
                                <tr key={index}>
                                  <td width="5%">
                                    <span className="text-white">
                                      {index + 1}
                                    </span>
                                  </td>
                                  <td>
                                    <p className="mb-0 text-white">
                                      {item?.zakreen_link}
                                    </p>
                                  </td>
                                  <td width="10%">
                                    <div className="py-2">
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          YoutubeVideoDelete(index)
                                        }
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          )}
                        </div>
                        <div className="row align-items-center mt-5">
                          <div className="col-md-12 order-1 order-sm-1 order-md-2">
                            <div className="form-group text-center text-sm-center text-md-end">
                              {/* <button className="btn btn-primary py-3 px-5">
                                Save
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorZakiriProfileDetail;
