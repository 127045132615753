import React from 'react'
import OwlCarousel from "react-owl-carousel";

function EventDetail() {
  return (


      <div className='py-5'>

        <div className="modal login-modal modal-light fade" id="Zakiri-Date-Modal" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">

              <div className="modal-body py-5">
                <button type="button" className="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                <div className='md-body text-center'>
                  <div className="modal-logo-div mb-4">
                    <h4><b>Choose Date & Time</b></h4>
                  </div>
                  <div className='login-form'>
                    <div className='mb-3 form-icon-group'>
                      <input className='form-control' placeholder='Select Date' type='date'/>
                      <span>
                        <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_171_630)">
<path
    d="M6.85714 0C7.80536 0 8.57143 0.782031 8.57143 1.75V3.5H15.4286V1.75C15.4286 0.782031 16.1946 0 17.1429 0C18.0911 0 18.8571 0.782031 18.8571 1.75V3.5H21.4286C22.8482 3.5 24 4.67578 24 6.125V8.75H0V6.125C0 4.67578 1.15179 3.5 2.57143 3.5H5.14286V1.75C5.14286 0.782031 5.90893 0 6.85714 0ZM0 10.5H24V25.375C24 26.8242 22.8482 28 21.4286 28H2.57143C1.15179 28 0 26.8242 0 25.375V10.5ZM3.42857 14.875V16.625C3.42857 17.1063 3.81429 17.5 4.28571 17.5H6C6.47143 17.5 6.85714 17.1063 6.85714 16.625V14.875C6.85714 14.3938 6.47143 14 6 14H4.28571C3.81429 14 3.42857 14.3938 3.42857 14.875ZM10.2857 14.875V16.625C10.2857 17.1063 10.6714 17.5 11.1429 17.5H12.8571C13.3286 17.5 13.7143 17.1063 13.7143 16.625V14.875C13.7143 14.3938 13.3286 14 12.8571 14H11.1429C10.6714 14 10.2857 14.3938 10.2857 14.875ZM18 14C17.5286 14 17.1429 14.3938 17.1429 14.875V16.625C17.1429 17.1063 17.5286 17.5 18 17.5H19.7143C20.1857 17.5 20.5714 17.1063 20.5714 16.625V14.875C20.5714 14.3938 20.1857 14 19.7143 14H18ZM3.42857 21.875V23.625C3.42857 24.1063 3.81429 24.5 4.28571 24.5H6C6.47143 24.5 6.85714 24.1063 6.85714 23.625V21.875C6.85714 21.3937 6.47143 21 6 21H4.28571C3.81429 21 3.42857 21.3937 3.42857 21.875ZM11.1429 21C10.6714 21 10.2857 21.3937 10.2857 21.875V23.625C10.2857 24.1063 10.6714 24.5 11.1429 24.5H12.8571C13.3286 24.5 13.7143 24.1063 13.7143 23.625V21.875C13.7143 21.3937 13.3286 21 12.8571 21H11.1429ZM17.1429 21.875V23.625C17.1429 24.1063 17.5286 24.5 18 24.5H19.7143C20.1857 24.5 20.5714 24.1063 20.5714 23.625V21.875C20.5714 21.3937 20.1857 21 19.7143 21H18C17.5286 21 17.1429 21.3937 17.1429 21.875Z"
    fill="#8A8A8A"/>
</g>
<defs>
<clipPath id="clip0_171_630">
<rect width="24" height="28" fill="white"/>
</clipPath>
</defs>
</svg>

                      </span>
                    </div>
                    <div className='mb-3 form-icon-group'>
                      <input className='form-control' placeholder='Select Time' type='time'/>
                      <span><svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
<path
    d="M25.375 14C25.375 17.0168 24.1766 19.9101 22.0433 22.0433C19.9101 24.1766 17.0168 25.375 14 25.375C10.9832 25.375 8.08989 24.1766 5.95666 22.0433C3.82343 19.9101 2.625 17.0168 2.625 14C2.625 10.9832 3.82343 8.08989 5.95666 5.95666C8.08989 3.82343 10.9832 2.625 14 2.625C17.0168 2.625 19.9101 3.82343 22.0433 5.95666C24.1766 8.08989 25.375 10.9832 25.375 14ZM0 14C0 17.713 1.475 21.274 4.10051 23.8995C6.72601 26.525 10.287 28 14 28C17.713 28 21.274 26.525 23.8995 23.8995C26.525 21.274 28 17.713 28 14C28 10.287 26.525 6.72601 23.8995 4.10051C21.274 1.475 17.713 0 14 0C10.287 0 6.72601 1.475 4.10051 4.10051C1.475 6.72601 0 10.287 0 14ZM12.6875 6.5625V14C12.6875 14.4375 12.9062 14.8477 13.2727 15.0938L18.5227 18.5938C19.1242 18.9984 19.9391 18.8344 20.3438 18.2273C20.7484 17.6203 20.5844 16.8109 19.9773 16.4062L15.3125 13.3V6.5625C15.3125 5.83516 14.7273 5.25 14 5.25C13.2727 5.25 12.6875 5.83516 12.6875 6.5625Z"
    fill="#8A8A8A"/>
</svg>
</span>
                    </div>

                    <div className='btn-div mb-3'>
                      <button className="btn btn-primary text-center w-100 py-3">
                        Next
                      </button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-8 col-xl-8'>
              <div className='event-step-div'>
                <ul className='event-step-ul'>
                  <li className='active'>
                    <span>1</span>
                    <p>Event Detail</p>
                  </li>
                  <li>
                    <span>2</span>
                    <p>Confirmation</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-7 col-lg-8 col-xl-8'>
              <div className='account-right mb-3'>
                <div className='dark-card p-3 p-sm-3 p-md-3 p-lg-5'>
                  <div className='dark-card-header'>
                    <h2 className='card-title mb-4'>Event Detail</h2>
                  </div>
                  <div className='dark-card-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Occasion *</label>
                          <input className='form-control' type='text' placeholder='Enter Occasion'/>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Address *</label>
                          <input className='form-control' type='text' placeholder='Enter Address'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Piece *</label>
                          <input className='form-control' type='text' placeholder='Enter Piece'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Region *</label>
                          <input className='form-control' type='text' placeholder='Enter Region'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Road *</label>
                          <input className='form-control' type='text' placeholder='Enter Road'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>home *</label>
                          <input className='form-control' type='text' placeholder='Enter home'/>
                        </div>
                      </div>

                      <div className='col-md-12'>
                        <div className='form-group text-end mt-4'>
                          <button className='btn btn-primary py-3 px-5'>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-4'>
              <div className='account-left mb-4'>
                <div className='dark-card mb-4'>
                  <div className='profile-img m-auto mb-3'>
                    <img src='/assets/images/zakereen.png' alt='img' className='img-fluid' width='100%'/>
                  </div>
                  <div className='profile-detail text-center'>
                    <h4>Muhammad Bou Jabbara</h4>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-6'>
                      <p className='text-light'>Date</p>
                    </div>
                    <div className='col-6'>
                      <p className='text-light text-end'>24-06-2024</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <p className='text-light mb-0'>Time</p>
                    </div>
                    <div className='col-6'>
                      <p className='text-light text-end mb-0'>07:00 PM</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>

        </div>

      </div>
  )
}

export default EventDetail