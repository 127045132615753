import React from 'react'

function UserLoggedInWarning() {
  return (
    <div>
        <p className='text-center'>Please Logged In To View Below Packages</p>
    </div>
  )
}

export default UserLoggedInWarning