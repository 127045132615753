import React, {Fragment} from 'react'

function ContactUs() {
  return (


      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='contact-info-left'>
                <div className='contact-box'>
                  <div className='cb-head'>
                    <span>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M9.55423 5.24003L6.17123 1.33503C5.78123 0.88503 5.06623 0.88703 4.61323 1.34103L1.83123 4.12803C1.00323 4.95703 0.766232 6.18803 1.24523 7.17503C4.10685 13.1 8.88528 17.8851 14.8062 20.755C15.7922 21.234 17.0222 20.997 17.8502 20.168L20.6582 17.355C21.1132 16.9 21.1142 16.181 20.6602 15.791L16.7402 12.426C16.3302 12.074 15.6932 12.12 15.2822 12.532L13.9182 13.898C13.8484 13.9712 13.7565 14.0195 13.6566 14.0354C13.5567 14.0513 13.4543 14.0339 13.3652 13.986C11.1357 12.7021 9.28622 10.8503 8.00523 8.61903C7.95726 8.52978 7.93989 8.42726 7.95578 8.32719C7.97168 8.22711 8.01996 8.13502 8.09323 8.06503L9.45323 6.70403C9.86523 6.29003 9.91023 5.65003 9.55423 5.23903V5.24003Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </span>
                    <h6>Call To Us</h6>
                  </div>
                  <div className='cb-body'>
                    <p>
                      We are available 24/7, 7 days a week.
                    </p>
                    <p>
                      Phone: +965 123456870
                    </p>
                  </div>
                </div>
                <div className='contact-box'>
                  <div className='cb-head'>
                    <span>
                   <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L11 8L21 1M1 15H21V1H1V15Z" stroke="white" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round"/>
</svg>

                  </span>
                    <h6>Write To US</h6>
                  </div>
                  <div className='cb-body'>
                    <p>
                      Fill out our form and we will contact you within 24 hours.
                    </p>
                    <p>
                      Emails: support@husainia.com
                    </p>
                    <p>
                      Emails: info@husainia.com
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div className='col-lg-8'>
              <div className='account-right mb-3'>
                <div className='dark-card p-3 p-sm-3 p-md-3 p-lg-5'>
                  <div className='dark-card-header'>
                    <h2 className='card-title mb-4'>Contact Us</h2>
                  </div>
                  <div className='dark-card-body'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Your Name *</label>
                          <input className='form-control' type='text' placeholder='Enter Your Name'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Your Email *</label>
                          <input className='form-control' type='email' placeholder='Enter Address'/>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Your Phone *</label>
                          <input className='form-control' type='text' placeholder='Enter Your Phone'/>
                        </div>
                      </div>

                      <div className='col-md-12'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Your Message *</label>
                          <textarea className='form-control' placeholder='Enter Road'/>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group text-end mt-4'>
                          <button className='btn btn-primary py-3 px-5'>
                            Submit
                          </button>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
  )
}

export default ContactUs