import React from 'react'

function CheckOut() {
  return (


      <div className='py-5'>
        <div className="modal login-modal modal-light fade" id="Request-Sent-Modal" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">

              <div className="modal-body py-5">

                <div className='md-body text-center'>
                  <div className='icon-box mb-3'>
                    <svg width="100" height="104" viewBox="0 0 100 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M50 1.625C22.379 1.625 0 24.1719 0 52C0 79.8281 22.379 102.375 50 102.375C77.621 102.375 100 79.8281 100 52C100 24.1719 77.621 1.625 50 1.625ZM50 92.625C27.7621 92.625 9.67742 74.4047 9.67742 52C9.67742 29.5953 27.7621 11.375 50 11.375C72.2379 11.375 90.3226 29.5953 90.3226 52C90.3226 74.4047 72.2379 92.625 50 92.625ZM33.871 48.75C37.4395 48.75 40.3226 45.8453 40.3226 42.25C40.3226 38.6547 37.4395 35.75 33.871 35.75C30.3024 35.75 27.4194 38.6547 27.4194 42.25C27.4194 45.8453 30.3024 48.75 33.871 48.75ZM66.129 48.75C69.6976 48.75 72.5806 45.8453 72.5806 42.25C72.5806 38.6547 69.6976 35.75 66.129 35.75C62.5605 35.75 59.6774 38.6547 59.6774 42.25C59.6774 45.8453 62.5605 48.75 66.129 48.75ZM66.9355 63.4969C62.7419 68.575 56.5524 71.5 50 71.5C43.4476 71.5 37.2581 68.5953 33.0645 63.4969C31.3508 61.425 28.2863 61.1609 26.25 62.8672C24.1935 64.5938 23.9315 67.6609 25.625 69.7328C31.6734 77.0453 40.5645 81.2297 50 81.2297C59.4355 81.2297 68.3266 77.0453 74.375 69.7328C76.0887 67.6609 75.8064 64.5938 73.75 62.8672C71.7137 61.1609 68.6492 61.425 66.9355 63.4969Z"
                          fill="#198855"/>
                    </svg>

                  </div>
                  <div className="modal-logo-div mb-4">
                    <h4 className='text-success'><b>The request has been sent</b></h4>
                  </div>
                  <p>
                    request has been sent for responses, and you will receive a Acceptance or rejection will be
                    announced soon
                  </p>
                  <p>
                    Thank you for your patience!
                  </p>
                  <div className='back-btn-div'>
                    <button className='btn btn-primary py-3 px-4'>Back to Home
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container'>

          <div className='row'>
            <div className='col-lg-8'>
              <div className='account-right mb-3'>
                <div className='dark-card p-3 p-sm-3 p-md-3 p-lg-5'>
                  <div className='dark-card-header'>
                    <h2 className='card-title mb-4'>Shipping Address</h2>
                  </div>

                  <div className='select-address-box mb-5'>
                    <div className='selectcheckbox'>
                      <input type='radio' name='selectaddress'/>
                      <label>Same as Billing Address</label>
                    </div>
                    <div className='selectcheckbox'>
                      <input type='radio' name='selectaddress' checked/>
                      <label>Use a Different Shipping Address</label>
                    </div>
                  </div>

                  <div className='dark-card-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Occasion *</label>
                          <input className='form-control' type='text' placeholder='Enter Occasion'/>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Address *</label>
                          <input className='form-control' type='text' placeholder='Enter Address'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Piece *</label>
                          <input className='form-control' type='text' placeholder='Enter Piece'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Region *</label>
                          <input className='form-control' type='text' placeholder='Enter Region'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>Road *</label>
                          <input className='form-control' type='text' placeholder='Enter Road'/>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-3'>
                          <label className='form-label'>home *</label>
                          <input className='form-control' type='text' placeholder='Enter home'/>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='checkout-right'>
                <div className='checkout-details'>
                  <h4 className='text-uppercase'>Order summary</h4>
                  <div className='checkout-value'>
                    <div className='row'>
                      <div className='col-6'>
                        <p>Subtotal</p>
                      </div>
                      <div className='col-6'>
                        <p className='text-end'>KD 3.00</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <p>Delivery Fee</p>
                      </div>
                      <div className='col-6'>
                        <p className='text-end'>KD 0.500</p>
                      </div>
                    </div>
                    <hr/>
                    <div className='row'>
                      <div className='col-6'>
                        <h5>Total Amount</h5>
                      </div>
                      <div className='col-6'>
                        <h5 className='text-end'>KD 3.500</h5>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <button className='btn mt-3 btn-primary text-uppercase w-100 py-3' data-bs-toggle='modal' data-bs-target='#Request-Sent-Modal'>Request order</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
  )
}

export default CheckOut