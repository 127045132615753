import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userContext } from "../context/UserContext";
import { url } from "../helpers";
import CartItem from "../components/CartItem";
function CheckOut() {
  const { cart } = useContext(userContext);

  const cart_packages_price = cart?.restaurant_package
    ?.map((item) => item?.package_unit_price * item?.package_minimum_unit)
    .reduce((total, price) => total + price, 0);

  const cart_qaari_packages_price = cart?.qaari_zakreen_paclages
    ?.map((item) => item?.package_unit_price * item?.package_minimum_unit)
    .reduce((total, price) => total + price, 0);

  const delevery_charge = 5;

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item text-primary">CheckOut</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="checkout-left">
              <div className="checkout-item-list-div">
                {cart?.restaurant_package?.length > 0 &&
                  cart?.restaurant_package?.map((item, index) => (
                    <CartItem item={item} key={index} type="Restaurant" />
                  ))}
                {cart?.qaari_zakreen_paclages?.length > 0 &&
                  cart?.qaari_zakreen_paclages?.map((item, index) => (
                    <CartItem key={index} item={item} type="Qaari" />
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="checkout-right">
              <div className="checkout-details">
                <h4 className="text-uppercase">Order summary</h4>
                <div className="checkout-value">
                  <div className="row">
                    <div className="col-6">
                      <p>Subtotal</p>
                    </div>
                    <div className="col-6">
                      <p className="text-end">
                        KD {cart_packages_price + cart_qaari_packages_price}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Delivery Fee</p>
                    </div>
                    <div className="col-6">
                      <p className="text-end">KD {delevery_charge}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-6">
                      <h5>Total Amount</h5>
                    </div>
                    <div className="col-6">
                      <h5 className="text-end">
                        KD{" "}
                        {cart_packages_price +
                          cart_qaari_packages_price +
                          delevery_charge}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Link
                        to={`/Address`}
                        className="btn mt-3 btn-primary text-uppercase w-100 py-3"
                      >
                        Continue
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckOut;
