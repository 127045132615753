import React from 'react'
import {Link} from "react-router-dom";

function Footer() {
  return (
    <footer className='front-footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-2'>
            <div className='footer-box footer-logo-box'>
              <a className="navbar-brand" href="#">
                <img src='/assets/images/logo.png' alt='img' className='img-fluid'/>
              </a>
              <p className='text-light'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </p>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='footer-box'>
              <h4>Top Restaurants</h4>
              <ul className='p-0 m-0'>
                <li>
                  <a href='#'>
                    Restaurant 1
                  </a>
                </li>
                <li>
                  <a href='#'>
                    Restaurant 2
                  </a>
                </li>
                <li>
                  <a href='#'>
                    Restaurant 3
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='footer-box'>
              <h4>Services</h4>
              <ul className='p-0 m-0'>
                <li>
                  <a href='#'>
                    Lecture
                  </a>
                </li>
                <li>
                  <a href='#'>
                    The Arrival
                  </a>
                </li>
                <li>
                  <a href='#'>
                    Reading the Koran
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='footer-box'>
              <h4>Company</h4>
              <ul className='p-0 m-0'>
                <li>
                  <a href='#'>
                    About Us
                  </a>
                </li>
                <li>
                  <Link to={`/ContactUs`}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <a href='#'>
                    Career
                  </a>
                </li>
                <li>
                  <a href='#'>
                    Team
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='footer-box'>
              <h4>Legal</h4>
              <ul className='p-0 m-0'>
                <li>
                  <Link to={`/TermsandCondition`}>
                    Terms & Conditions
                  </Link>
                  <a href='#'>

                  </a>
                </li>
                <li>
                  <Link to={`/CookiePolicy`}>
                    Cookie Policy
                  </Link>
                  <a href='#'>

                  </a>
                </li>
                <li>
                  <Link to={`/PrivacyPolicy`}>
                    Privacy Policy
                  </Link>

                </li>
              </ul>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='footer-box'>
              <h4>Follow us on</h4>
              <div className='footer-social'>
                <a href='#'><i className="fab fa-facebook-square"></i></a>
                <a href='#'><i className="fab fa-twitter-square"></i></a>
                <a href='#'><i className="fab fa-instagram-square"></i></a>
                <a href='#'><i className="fab fa-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-footer'>
          <p className='m-0'>©2024 Husainia</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer