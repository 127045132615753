import React from 'react'
import OwlCarousel from "react-owl-carousel";

function ConfirmReservations() {
  return (


      <div className='py-5'>

        <div className="modal login-modal modal-light fade" id="Cancel-Res-Modal" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">

              <div className="modal-body py-5">

                <div className='md-body text-center'>
                  <div className='icon-box mb-3'>
                    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M50.375 0C22.5469 0 0 22.5469 0 50.375C0 78.2031 22.5469 100.75 50.375 100.75C78.2031 100.75 100.75 78.2031 100.75 50.375C100.75 22.5469 78.2031 0 50.375 0ZM75.075 63.5984C76.0297 64.5531 76.0297 66.0969 75.075 67.0516L67.0312 75.075C66.0766 76.0297 64.5328 76.0297 63.5781 75.075L50.375 61.75L37.1516 75.075C36.1969 76.0297 34.6531 76.0297 33.6984 75.075L25.675 67.0312C24.7203 66.0766 24.7203 64.5328 25.675 63.5781L39 50.375L25.675 37.1516C24.7203 36.1969 24.7203 34.6531 25.675 33.6984L33.7188 25.6547C34.6734 24.7 36.2172 24.7 37.1719 25.6547L50.375 39L63.5984 25.675C64.5531 24.7203 66.0969 24.7203 67.0516 25.675L75.0953 33.7188C76.05 34.6734 76.05 36.2172 75.0953 37.1719L61.75 50.375L75.075 63.5984Z"
                          fill="#FF4A4A"/>
                    </svg>


                  </div>
                  <div className="modal-logo-div mb-4">
                    <h4 className='text-danger'><b>Are you sure you have cancel reservation!</b></h4>
                  </div>

                  <div className='back-btn-div gap-3 d-flex justify-content-center'>
                    <button className='btn btn-outline-danger py-3 px-5'>Yes</button>
                    <button className='btn btn-outline-primary py-3 px-5'>No</button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="modal login-modal modal-light fade" id="Request-Sent-Modal" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">

              <div className="modal-body py-5">

                <div className='md-body text-center'>
                  <div className='icon-box mb-3'>
                    <svg width="100" height="104" viewBox="0 0 100 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M50 1.625C22.379 1.625 0 24.1719 0 52C0 79.8281 22.379 102.375 50 102.375C77.621 102.375 100 79.8281 100 52C100 24.1719 77.621 1.625 50 1.625ZM50 92.625C27.7621 92.625 9.67742 74.4047 9.67742 52C9.67742 29.5953 27.7621 11.375 50 11.375C72.2379 11.375 90.3226 29.5953 90.3226 52C90.3226 74.4047 72.2379 92.625 50 92.625ZM33.871 48.75C37.4395 48.75 40.3226 45.8453 40.3226 42.25C40.3226 38.6547 37.4395 35.75 33.871 35.75C30.3024 35.75 27.4194 38.6547 27.4194 42.25C27.4194 45.8453 30.3024 48.75 33.871 48.75ZM66.129 48.75C69.6976 48.75 72.5806 45.8453 72.5806 42.25C72.5806 38.6547 69.6976 35.75 66.129 35.75C62.5605 35.75 59.6774 38.6547 59.6774 42.25C59.6774 45.8453 62.5605 48.75 66.129 48.75ZM66.9355 63.4969C62.7419 68.575 56.5524 71.5 50 71.5C43.4476 71.5 37.2581 68.5953 33.0645 63.4969C31.3508 61.425 28.2863 61.1609 26.25 62.8672C24.1935 64.5938 23.9315 67.6609 25.625 69.7328C31.6734 77.0453 40.5645 81.2297 50 81.2297C59.4355 81.2297 68.3266 77.0453 74.375 69.7328C76.0887 67.6609 75.8064 64.5938 73.75 62.8672C71.7137 61.1609 68.6492 61.425 66.9355 63.4969Z"
                          fill="#198855"/>
                    </svg>

                  </div>
                  <div className="modal-logo-div mb-4">
                    <h4 className='text-success'><b>The request has been sent</b></h4>
                  </div>
                  <p>
                    request has been sent for responses, and you will receive a Acceptance or rejection will be
                    announced soon
                  </p>
                  <p>
                    Thank you for your patience!
                  </p>
                  <div className='back-btn-div'>
                    <button className='btn btn-primary py-3 px-4'>Back to Home
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12 col-lg-8 col-xl-8'>
              <div className='event-step-div'>
                <ul className='event-step-ul'>
                  <li className='active'>
                    <span><i className="fas fa-check"></i></span>
                    <p>Event Detail</p>
                  </li>
                  <li className='active'>
                    <span>2</span>
                    <p>Confirmation</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-md-7 col-lg-8 col-xl-8'>
              <button className='btn mb-3 text-white'><i class="fas fa-arrow-left"></i> Back</button>
              <div className='account-right mb-3'>
                <div className='res-dark-card mb-5'>
                  <div className='res-header'>
                    <div className='res-img-div'>
                      <img src='/assets/images/zakereen.png' alt='img' className='img-fluid' width='100%'/>
                    </div>
                    <div className='res-name-div'>
                      <h2>Muhammad Bou Jabbara</h2>
                      <p>Singer</p>
                    </div>
                  </div>
                  <div className='res-dark-body'>
                    <div className='row'>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Date</label>
                          <h6>24-06-2024</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>time</label>
                          <h6>07:00 PM</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Occasion</label>
                          <h6>Death</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Location</label>
                          <h6>Husseiniya Sayyid
                            al-Shuhada</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>package</label>
                          <h6>60 minutes</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>the service</label>
                          <h6>Arrival</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>price</label>
                          <h6>200 KD</h6>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='res-dark-footer text-end'>
                    <button className='btn btn-danger' data-bs-toggle='modal'
                            data-bs-target='#Cancel-Res-Modal'>Cancellation</button>
                    <button className='btn btn-primary' data-bs-toggle='modal' data-bs-target='#Request-Sent-Modal'>Reservation

                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
  )
}

export default ConfirmReservations