import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../context/UserContext';

function QaariPackageDetails(props) {
    const { cart,AddToCart } = useContext(userContext)
    const { Qaaripackages } = props;
    const [isReserved,setIsReserved] = useState(false)

    useEffect(() => {
        if (cart?.qaari_zakreen_paclages) {

            let result = cart?.qaari_zakreen_paclages?.filter(item => {
                if ((item?.qaari_zakreen == Qaaripackages?.parent) && (item?.package_name == Qaaripackages?.package_name)) {
                    return item
                }
            })

            if (result?.length > 0) {
                setIsReserved(true)
            }
        }
    }, [cart])

    return (
        <div className="item" >
            <div className="dark-card package-card">
                <div className="res-pac-img package-pac-img mb-3">
                    <h3 className="font-bold">
                        {Qaaripackages?.package_name}
                    </h3>
                </div>
                <div className="res-pac-detail text-start">
                    <h4 className="font-bold">
                        {Qaaripackages?.package_unit_price}KD
                    </h4>
                    <h6>Occasions : Birthday</h6>
                    <p className="text-light">
                        {Qaaripackages?.package_description}
                    </p>
                </div>
                <div className="res-pac-detail">
                    <button onClick={() => {
                        setIsReserved(!isReserved)
                        AddToCart('Qaari', {
                            "package_name": Qaaripackages?.package_name,
                            "package_unit_price": Qaaripackages?.package_unit_price,
                            "package_description": Qaaripackages?.package_description,
                            "package_minimum_unit": isReserved ? 0 : 1,
                            "qaari_zakreen": Qaaripackages?.parent
                        })
                    }} className="btn btn-primary w-100">
                        {isReserved ? 'Reserved' : 'Reservation'}
                        
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QaariPackageDetails