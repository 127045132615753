import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../context/UserContext';

function CartItem(props) {
    const { AddToCart, DeletePacakge, cart } = useContext(userContext);
    const { item, type } = props;
    const [Qty, setQty] = useState(1)
    const [isReserved, setIsReserved] = useState(false)

    useEffect(() => {
        if (type == 'Restaurant') {
            setQty(item?.package_minimum_unit)
        } else {
            if (cart?.qaari_zakreen_paclages) {

                let result = cart?.qaari_zakreen_paclages?.filter(Qaaripackages => {
                    if ((Qaaripackages?.qaari_zakreen == item?.qaari_zakreen) && (Qaaripackages?.package_name == item?.package_name)) {
                        return Qaaripackages
                    }
                })

                if (result?.length > 0) {
                    setIsReserved(true)
                }
            }
        }
        console.log('qaari',cart?.qaari_zakreen_paclages)
        console.log('item',item)
    }, [item])

    return (
        <div className="checkout-menu">
            <div className="resto-menu-card position-relative">
                <div className="resto-img-div">
                    <img
                        src="/assets/images/item-img2.png"
                        alt="img"
                        className="img-fluid"
                    />
                </div>
                <div className="resto-item-detail">
                    <h4>{item?.package_name}</h4>
                    <h5>{item?.package_unit_price} KD</h5>
                    <p className="text-light">
                        {item?.package_description}
                    </p>
                    <div className="qty-group-box">
                        {type == 'Restaurant' && <>
                            <button onClick={() => {
                                if (Qty > 0) {
                                    setQty(Qty - 1) // 2 -1 = 1

                                    AddToCart('restaurant', {
                                        "package_id": item?.package_id,
                                        "package_name": item?.package_name,
                                        "package_unit_price": item?.package_unit_price,
                                        "package_description": item?.package_description,
                                        "package_minimum_unit": Qty - 1,
                                        "restaurant_owner": item?.restaurant_owner
                                    })
                                }
                            }} className="btn btn-minus">
                                <i className="fas fa-minus"></i>
                            </button>
                            <input
                                className="form-control"
                                type="number"
                                readOnly
                                value={Qty}
                            />
                            <button
                                className="btn btn-plus"
                                onClick={() => {
                                    setQty(Qty + 1)

                                    AddToCart('restaurant', {
                                        "package_id": item?.package_id,
                                        "package_name": item?.package_name,
                                        "package_unit_price": item?.package_unit_price,
                                        "package_description": item?.package_description,
                                        "package_minimum_unit": Qty + 1,
                                        "restaurant_owner": item?.restaurant_owner
                                    })
                                }}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                        </>}

                    </div>
                </div>
                <button
                    className="btn text-danger item-delete-btn"
                    onClick={() => {

                        if (type == 'Restaurant'){
                            setQty(0) // 2 -1 = 1

                            AddToCart('restaurant', {
                                "package_id": item?.package_id,
                                "package_name": item?.package_name,
                                "package_unit_price": item?.package_unit_price,
                                "package_description": item?.package_description,
                                "package_minimum_unit": 0,
                                "restaurant_owner": item?.restaurant_owner
                            })
                        }else{
                            AddToCart('Qaari', {
                                "package_name": item?.package_name,
                                "package_unit_price": item?.package_unit_price,
                                "package_description": item?.package_description,
                                "package_minimum_unit": isReserved ? 0 : 1,
                                "qaari_zakreen": item?.qaari_zakreen
                            })
                        }

                        
                    }}
                >
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
    )
}

export default CartItem